<template>
  <div class="centre-main">
    <div class="centre-menu">
      <div class="menu-list">
        <div class="menu-item" v-for="(menu, index) in menuList" :key="index">
          <div class="item-title">
            <img style="width:20px;margin-right:5px" :src="menu.url" alt="" />

            <span>{{ menu.name }}</span>
          </div>
          <div class="item-list">
            <router-link
              class="item-router"
              :to="child.path"
              v-for="(child, index) in menu.childMenu"
              :key="index"
            >
              <img
                style="width:18px;margin-top:10px;margin-bottom: 5px;"
                :src="child.url"
                alt=""
              />

              <div style="margin-bottom: 10px;">{{ child.name }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="centre-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          name: "用户中心",
          url: require("@/assets/images/yonghu.png"),
          childMenu: [
            {
              name: "个人信息",
              url: require("@/assets/images/geren.png"),
              path: "/centre/personalCenter",
            },
            {
              name: "地址管理",
              url: require("@/assets/images/dizhi.png"),
              path: "/centre/addressManager",
            },
          ],
        },
        {
          name: "订单中心",
          url: require("@/assets/images/dingdan.png"),

          childMenu: [
            {
              url: require("@/assets/images/mine.png"),

              name: "我的订单",
              path: "/centre/ordersList",
            },
            {
              name: "我的收藏",
              url: require("@/assets/images/shoucang.png"),

              path: "/centre/myCollection",
            },
          ],
        },
        {
          url: require("@/assets/images/jifen.png"),

          name: "积分中心",
          childMenu: [
            // {
            //     name: "积分流水",
            //     path: "/centre/pointBilling"
            // },
            {
              url: require("@/assets/images/jihuo.png"),

              name: "积分卡激活",
              path: "/centre/pointInvest",
            },
            {
              url: require("@/assets/images/shiwu.png"),

              name: "我的实物卡",
              path: "/centre/cardList",
            },
          ],
        },
        {
          url: require("@/assets/images/huiyuan.png"),

          name: "会员权益",
          childMenu: [
            {
              url: require("@/assets/images/bangzhu.png"),

              name: "帮助中心",
              path: "/helpCenter/helpPerson",
            },
            {
              url: require("@/assets/images/guanyu.png"),

              name: "关于积分",
              path: "/helpCenter/helpAboutUs",
            },
            {
              url: require("@/assets/images/kefuu.png"),

              name: "联系客服",
              path: "/centre/customerService",
            },
            {
              url: require("@/assets/images/gonggao.png"),

              name: "通知公告",
              path: "/centre/notice",
            },
          ],
        },
      ],
    };
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.centre-main {
  width: 100%;
  display: flex;
  flex-direction: row;

  .centre-menu {
    margin-top: 20px;
    width: 190px;
    height: auto;

    .menu-item {
      width: 100%;
      // height: 110px;
      margin-bottom: 20px;

      .item-title {
        height: 35px;
        display: flex;
        // height: 24px;
        width: 100%;
        line-height: 28px;
        text-align: center;
        color: #fff;
        background-color: #71a2f9;
        border-radius: 2px 2px 0 0;
        font-weight: bold;
        justify-content: center;
        align-items: center;

        span {
          font-size: 14px;
        }
      }

      .item-list {
        width: 100%;
        flex-wrap: wrap;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 1px solid #2283e2;
        // border-left: 1px solid #2283e2;
        // border-right: 1px solid #2283e2;

        .item-router {
          width: 50%;
          text-align: center;
          // line-height: 30px;
          cursor: pointer;
        }

        .item-router:hover {
          color: #2283e2;
        }
      }
    }
  }

  .centre-content {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    margin-left: 20px;
    flex: 1;
  }
}

.router-link-active {
  color: #2283e2;
}
</style>
